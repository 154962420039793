import styled from "styled-components";

import { PrimaryButton, SecondaryButton } from "src/shared/StyledComponents";

export const RentDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px 10px;
  width: 95%;
  background: #ffffff;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  text-align: center;
  gap: 2rem;
  font-weight: 600;
  margin-block: 20px;
  font-size: 16px;

  .heading {
    font-size: 18px;
  }
  span {
    font-weight: 800;
  }
`;

export const Text = styled.div`
  color: #2d4764;
  font-size: 18px;
  font-weight: 700;
`;

export const ClickableText = styled.div`
  color: #2d4764;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
`;

export const StyledSecondaryButton = styled(SecondaryButton)`
  font-size: 20px;
  font-weight: 800;
  padding-inline: 20px;
  border-radius: 100px;
`;

export const StyledLoaderButton = styled(SecondaryButton)`
  font-size: 5px;
  font-weight: 800;
  padding-inline: 30px;
  border-radius: 100px;
`;

export const StyledRentingOption = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: 70%;
  font-size: 16px;
  list-style-type: disc;
`;

export const StyledButton = styled(PrimaryButton)`
  padding-inline: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;

export const StyledButtons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as Angle } from "../../assets/angle1.svg";
import { ReactComponent as Flat } from "../../assets/flat1.svg";
import { ReactComponent as Tent } from "../../assets/tent1.svg";

interface ModuleSelectionType {
  activeGridChanged: any;
  documentRef: any;
}

const ModuleSelection: React.FC<ModuleSelectionType> = ({
  activeGridChanged, // used to reload on change
  documentRef
}) => {
  const { t } = useTranslation();
  const [angleValue, setAngleValue] = useState<number>(
    documentRef.current?.activeGrid?.angle ?? 0
  )
  const [key, setKey] = useState<number>(0);

  const handleButtonClick = (buttonType: string):void => {
    switch(buttonType) {
      case "parallel":
        documentRef.current.activeGrid.angle = 30;
        break;
      case "tent":
        documentRef.current.activeGrid.angle = 10;
        break;
      default:
        documentRef.current.activeGrid.angle = 0;
        break;
    }
    setAngleValue(documentRef.current.activeGrid.angle);
    documentRef.current.activeGrid.setup = buttonType;
    documentRef.current.activeGrid.disposeOccupancy();
    documentRef.current.activeGrid.recomputeOccupancy({
      setup: documentRef.current.activeGrid.setup,
      angle: documentRef.current.activeGrid.angle,
      gridFaceUp: documentRef.current.activeGrid.gridFaceUp,
      targets: documentRef.current.terrainGroup.children,
    });
    documentRef.current.activeGrid.redrawOccupancy(documentRef.current.activeGrid.setup, documentRef.current.activeGrid.angle);
    setKey(Math.random());
  };

  const handleAngleChange = (event: React.ChangeEvent<HTMLInputElement>):void => {
    documentRef.current.activeGrid.angle = Number(event.target.value);
    setAngleValue(Number(event.target.value));
    documentRef.current.activeGrid.disposeOccupancy();
    documentRef.current.activeGrid.recomputeOccupancy({
      setup: documentRef.current.activeGrid.setup,
      angle: documentRef.current.activeGrid.angle,
      gridFaceUp: documentRef.current.activeGrid.gridFaceUp,
      targets: documentRef.current.terrainGroup.children,
    });
    documentRef.current.activeGrid.redrawOccupancy(documentRef.current.activeGrid.setup, documentRef.current.activeGrid.angle);
  };
  
  return (
    <div className="flex-1 p-2 bg-white" key={key}>
      {documentRef.current.activeGrid ? (
        <div>
          {/* Text */}
          <span className="text-xl font-semibold text-[#2B3674] block mb-4">
            {t("3D_VIEW_MOUNTING")}
          </span>
          
          {/* Buttons Container */}
          <div className="flex space-x-1 mb-4">
            {/* Button 1 */}
            <button className="w-24 p-2 rounded border border-blue-700 flex flex-col items-center justify-center space-y-2">
              <Flat
                className="w-14 h-14"
                onClick={() => handleButtonClick("flat")}
              />
              <span>
                {t("3D_VIEW_FLUSH")}
              </span>
            </button>
      
            {/* Button 2 */}
            <button className="w-24 p-2 rounded border border-blue-700 flex flex-col items-center justify-center space-y-2">
              <Angle
                className="w-14 h-14"
                onClick={() => handleButtonClick("parallel")}
              />
              <span>
                {t("3D_VIEW_ANGLED")}
              </span>
            </button>
      
            {/* Button 3 */}
            <button className="w-24 p-2 rounded border border-blue-700 flex flex-col items-center justify-center space-y-2">
              <Tent
                className="w-14 h-14"
                onClick={() => handleButtonClick("tent")}
              />
              <span>
                {t("3D_VIEW_TENT")}
              </span>
            </button>
          </div>
          <div key={key}>
            {(documentRef.current.activeGrid.setup === "parallel" || documentRef.current.activeGrid.setup === "tent") && (
              <div>
              <label htmlFor="angleinput" className="block text-sm font-medium text-gray-700">
                {t("3D_VIEW_INCLINATION")}
              </label>
              <input
                id="angleinput"
                type="number"
                value={angleValue}
                onChange={handleAngleChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              />
              </div>
            )}
          </div>
        </div>
      ):(
        <span>
          {t("3D_VIEW_NO_GRID_SELECTED")}
        </span>
      )}
      
    </div>
  );
};

export default ModuleSelection;

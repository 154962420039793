import { toast } from "react-toastify";

import { MainUserState } from "src/redux/user";

export const userIsNotAuthenticated = (user: MainUserState | null): boolean => {
  return !user;
};

export const userIsAuthenticated = (user: MainUserState | null): boolean => {
  return !userIsNotAuthenticated(user);
};

export const imageUrlToBase64 = async (url: string): Promise<any> => {
  const data = await fetch(url);
  const blob = await data.blob();
  return await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
    reader.onerror = reject;
  });
};

export const autoStringAssignment = (  grids: any[],
  totalMPPTCount: number,
  azimuthTolerance: number = 10) => 

 {
  const strings: any[] = [];
  const usedMPPTCount = 0;

  // Group grids by roof
  const groupedGrids: Record<string, any[]> = {};
  grids.forEach((grid) => {
    const roofKey = Object.keys(groupedGrids).find((key) => {
      const azimuthDiff = Math.abs(
        groupedGrids[key][0].azimuth - grid.azimuth
      );
      return azimuthDiff <= azimuthTolerance;
    });

    if (roofKey) {
      groupedGrids[roofKey].push(grid);
    } else {
      groupedGrids[`roof-${Object.keys(groupedGrids).length}`] = [grid];
    }
  });

  console.log("Grouped Grids by Roof:", groupedGrids);

  const groupedPanelsCount = Object.values(groupedGrids).map((arr) => arr.reduce((acc, panel) => acc + panel.moduleQuantity, 0));
  console.log("groupedPanelsCount", groupedPanelsCount)

  const totalPanels = groupedPanelsCount.reduce((acc, c) => acc + c, 0);
  let currentMPPTTarget = totalMPPTCount;
  let currentPanelTarget = totalPanels / currentMPPTTarget;

  console.log("currentMPPTTarget", currentMPPTTarget);
  console.log("currentPanelTarget", currentPanelTarget);

  while (currentMPPTTarget > 0) {
    if (groupedPanelsCount.every((c) => !(c % currentPanelTarget))) {
      console.log("Utilised MPP:", currentMPPTTarget);
      console.log("Finalised group count", currentPanelTarget);
      const result: any = [];
      let currentPanelCount = 0;
      let groupIndex = 1;
      let gridsOffset = 0;

      for (const [roofKey, grids] of Object.entries(groupedGrids)) {
        grids.forEach((grid: any, gridIndex: number) => {
          for (let i = 0; i < grid.moduleQuantity; i++) {
        if (currentPanelCount % currentPanelTarget === 0) {
          let randomColor;
          do {
            randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
          } while (randomColor === "#ffffff"); 
          
          result.push({
            name: `string-${groupIndex}`,
            color: randomColor,
            value: []
          });
          groupIndex++;
        }
        result[result.length - 1].value.push({
          gridIndex: gridIndex + gridsOffset,
          panelIndex: i
        });
        currentPanelCount++;
          }
        });
        gridsOffset += grids.length;
      }

      return result;
    }
    currentMPPTTarget--;
    currentPanelTarget = totalPanels / currentMPPTTarget;
  }

  toast.warning("Grouping of panels not possible for the current inverter")

  return strings;
}

import { Input } from "antd";
import { ChevronRight, Edit, Save,  } from "lucide-react";
import React, { useState } from "react";

import { useNumberFormat } from "src/hooks";


interface AutoStringInputProps {
  loop: any;
  index: number;
  onEdit: (index: number, name: string) => any;
  selectedProducts: any;
}

const AutoStringInput: React.FC<AutoStringInputProps> = ({
  loop,
  index,
  onEdit,
  selectedProducts
}) => {
  const [isEditing, setIsEditing] = useState(false); 
  const [inputValue, setInputValue] = useState(loop?.[0]?.name || ""); 
  const {formatNumber} = useNumberFormat();
  const connectedPanelsToString = loop.length;
  const VOC_STC = selectedProducts?.solarPanel?.VOC_STC
  const T_COEFFICIENT = selectedProducts?.solarPanel?.T_COEFFICIENT
  const T_MIN = selectedProducts?.solarPanel?.T_MIN
  const T_STC = selectedProducts?.solarPanel?.T_STC

  const VOC_ADJUSTED =  VOC_STC * (1 + T_COEFFICIENT * (T_MIN - T_STC));
  const VDC_MAX = VOC_ADJUSTED * connectedPanelsToString;

  const handleEditClick = () => {
    setIsEditing(true); 
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    onEdit(index, inputValue); 
  };

  return (
    <div className="flex items-center gap-2 pt-2">
      <ChevronRight className="w-5 h-5" />
      {isEditing ? (
        <Input
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        className="w-1/2 outline-none rounded-md"
      />
      ) : (
        <h3 className="font-medium">{loop?.[0]?.name}</h3>
      )}
      {isEditing ? (
        <Save 
        className="w-4 h-4 cursor-pointer"
        onClick={handleSaveClick}
        />
      ) : (
        <Edit
          className="w-4 h-4 cursor-pointer"
          onClick={handleEditClick}
        />
      )}
      <div
        className="w-2 h-2 rounded-full"
        style={{ background: loop?.[0]?.color || "green" }}
      />
      <div>{formatNumber(VDC_MAX)}V</div>
    </div>
  );
};

export default AutoStringInput;

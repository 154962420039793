import { Button } from "antd";
import { ChevronDown } from "lucide-react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { useNumberFormat } from "src/hooks";

import { ProductDB, ProductWithPrice } from "src/db-types";

import { ReactComponent as Bulb } from "../../assets/configuration/Bulb.svg";

import AutoStringInput from "./AutoStringInput";

import { ProjectActions } from "src/redux/actionCreators";
import { getInverters } from "src/redux/products/selector";

interface InverterSelectionType {
  onClickOfAutoStrings: () => boolean;
  selectedProducts: {
 inverter: ProductDB | null;
    interterOutput: number;
    solarPanel: ProductDB | null ;
  };
  cablingLoops: any[];
  setCablingLoops: any;
}
interface CablingLoop {
  color: string;
  name: string;
}
type OnEditFunction = (groupId: number, name: string) => void;

const InverterSelection: React.FC<InverterSelectionType> = ({
  onClickOfAutoStrings,
  cablingLoops,
  selectedProducts,
  setCablingLoops,
}) => {
  const { t } = useTranslation();
  const { formatNumber } = useNumberFormat();
  const dispatch = useDispatch();

  const inverters = useSelector(getInverters).filter((inverter) => inverter.instock);

  const onEdit: OnEditFunction = (groupId, name) => {
    setCablingLoops((loops: CablingLoop[][]) => {
      return loops.map((loop, index) => {
        return loop.map((lp: CablingLoop) => {
          if (index === groupId) {
            lp.name = name;
          }
          return lp;
        });
      });
    });
  };
  const maxDcVoltage = useMemo(() => {
    let maxDcVoltage = 0;
    cablingLoops.forEach((loop) => {
      const connectedPanelsToString = loop.length;
      const solarPanel = selectedProducts?.solarPanel as ProductWithPrice;
      const VOC_STC = solarPanel?.VOC_STC;
      const T_COEFFICIENT = solarPanel?.T_COEFFICIENT;
      const T_MIN = solarPanel?.T_MIN;
      const T_STC = solarPanel?.T_STC;

      const VOC_ADJUSTED = VOC_STC * (1 + T_COEFFICIENT * (T_MIN - T_STC));
      const VDC_MAX = VOC_ADJUSTED * connectedPanelsToString;
      maxDcVoltage = maxDcVoltage + VDC_MAX;
    });
    return maxDcVoltage;
  }, [cablingLoops, selectedProducts?.solarPanel]);

  const maxDcCurrent = useMemo(() => {
    const solarPanel = selectedProducts?.solarPanel as ProductWithPrice;
    const I_SC = solarPanel?.I_SC;
    const connectedPanelsToString = cablingLoops.length;
    return I_SC * connectedPanelsToString * 1.25;
  }, [cablingLoops, selectedProducts?.solarPanel]);

  const chooseOptimalInverter = (): void => {
    if (!cablingLoops.length) {
      const isSuccess = onClickOfAutoStrings();
      if (!isSuccess) return;
    }

    console.log({ inverters, maxDcVoltage,  maxDcCurrent });
    const isFisibleInverter = inverters
      .filter(
        (inverter) =>
          inverter.maxDCInputVoltage >= maxDcVoltage && inverter.maxDCCurrent >= maxDcCurrent,
      )
      .sort((a, b) => a.maxDCInputVoltage - b.maxDCInputVoltage);
    if (isFisibleInverter.length > 0) {
      dispatch(
        ProjectActions.updateInverter({
          item: isFisibleInverter[0],
        }),
      );
    } else {
      toast.warn(t("No inverter available for this configuration"));
    }
  };

  const warning = useMemo(() => {
    if(!selectedProducts.inverter) return null;
    const maxDCInputVoltage = selectedProducts?.inverter?.maxDCInputVoltage;
    const maxDCInputCurrent = selectedProducts?.inverter?.maxDCCurrent;
    if (maxDcVoltage >= maxDCInputVoltage || maxDcCurrent >= maxDCInputCurrent) {
      return (
        <div className="warning-message">
          <p className="warning-text">⚠️ Warning:</p>
          <ul className="warning-details list-disc pl-4">
            {maxDcVoltage >= maxDCInputVoltage && (
              <li>
                Max DC Voltage ({formatNumber(maxDcVoltage.toFixed(2))}V) exceeds inverter limit (
                {maxDCInputVoltage}V).
              </li>
            )}
            {maxDcCurrent >= maxDCInputCurrent && (
              <li>
                Max DC Current ({formatNumber(maxDcCurrent.toFixed(2))}A) exceeds inverter limit (
                {maxDCInputCurrent}A).
              </li>
            )}
          </ul>
        </div>
      );
    }
    return null;
  }, [formatNumber, maxDcCurrent, maxDcVoltage, selectedProducts.inverter]);
  
  return (
    <div className="flex-1 p-2 bg-white max-w-80">
      <div className="mb-6">
        {
          warning !== null && (
            <Button
            onClick={chooseOptimalInverter}
            className="w-auto text-sm max-w-md bg-[#FFD66B] hover:bg-[#FFD66B]/90 text-[#2B3674] h-[38px] rounded-full flex justify-center items-center gap-2"
          >
            <Bulb />
            {t("Choose optimal inverter")}
          </Button>
          )
        }
      </div>
      {warning}
      <div className="space-y-4">
        <div className="bg-white p-2 rounded-lg">
          <div className="flex items-center gap-2 mb-4">
            <ChevronDown className="w-5 h-5 text-[#2B3674]" />
            <h2 className="text-lg font-medium text-[#2B3674]">
              {selectedProducts?.inverter?.name}
            </h2>
            {/* <span className="bg-[#FFD66B] px-2 py-0.5 rounded-full text-sm">{90}%</span> */}
          </div>

          <div className="ml-6 space-y-2 text-[#2B3674]">
            <p>
              1 * {formatNumber((selectedProducts?.inverter?.applicableMaxCapacity ?? 0) / 1000)} kWp ={" "}
              {selectedProducts.interterOutput} kWP
            </p>
            {!!maxDcVoltage && (
              <p>
                {t("Maximum voltage")} (Voc): {maxDcVoltage.toFixed(2)} V
              </p>
            )}
            {!isNaN(maxDcCurrent) && !!maxDcCurrent && (
              <p>
                {t("Maximum current")} (Isc): {maxDcCurrent.toFixed(2)} A
              </p>
            )}
            {selectedProducts?.inverter?.maxOutputPower && (
              <p>
                {t("Power")} {selectedProducts?.inverter?.maxOutputPower} kWp
              </p>
            )}
            <div className="mt-4">
              {cablingLoops?.map((loop, index) => (
                <AutoStringInput
                  loop={loop}
                  onEdit={onEdit}
                  key={index}
                  index={index}
                  selectedProducts={selectedProducts}
                />
              ))}
            </div>
          </div>
        </div>
        {/* <Button className="w-1/2 max-w-md bg-[#FFD66B] hover:bg-[#FFD66B]/90 text-[#2B3674] h-12 rounded-full flex justify-center items-center gap-2">
        <Plus className="w-5 h-5 mr-2" />
        Add Inverter
      </Button> */}

        <Button
          onClick={onClickOfAutoStrings}
          className="w-1/2 max-w-md bg-[#FFD66B] hover:bg-[#FFD66B]/90 text-[#2B3674] h-10 rounded-full flex justify-center items-center gap-2"
        >
          <Plus className="w-5 h-5 mr-2" />
          {t("Auto-String")}
        </Button>
      </div>
    </div>
  );
};

function Plus(props: React.ComponentProps<"svg">) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M12 5v14" />
      <path d="M5 12h14" />
    </svg>
  );
}

export default InverterSelection;
